<template>
  <section
    id="home"
    class="pa-0"
  >
    <intro />

  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: ''
      }
    },

    components: {
      Intro: () => import('@/components/Intro')
    },

    data: () => ({
      items: ['/static/pile-of-files.jpg']
    }),

    computed: {
      cardFeatures () {
        return []
      },
      listFeatures () {
        return []
      },
      testimonials () {
        return []
      }
    }
  }
</script>
